<script setup lang="ts"></script>

<template>
  <svg
    width="31"
    height="15"
    viewBox="0 0 31 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30 8.5C30.5523 8.5 31 8.05228 31 7.5C31 6.94772 30.5523 6.5 30 6.5L30 8.5ZM0.292893 6.79289C-0.0976314 7.18341 -0.0976315 7.81658 0.292892 8.2071L6.65685 14.5711C7.04738 14.9616 7.68054 14.9616 8.07107 14.5711C8.46159 14.1805 8.46159 13.5474 8.07107 13.1569L2.41421 7.5L8.07107 1.84314C8.46159 1.45262 8.46159 0.819455 8.07107 0.42893C7.68054 0.0384059 7.04738 0.0384058 6.65686 0.42893L0.292893 6.79289ZM30 6.5L1 6.5L1 8.5L30 8.5L30 6.5Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
