<script setup lang="ts">
import PlayIcon from "@/components/svg/PlayIcon.vue";
import { inject, onMounted, ref } from "vue";
import DownloadIcon from "@/components/svg/DownloadIcon.vue";
import ShareIcon from "@/components/svg/ShareIcon.vue";
import LeftArrow from "@/components/svg/LeftArrow.vue";
import RepeatIcon from "@/components/svg/RepeatIcon.vue";
const player = ref<HTMLVideoElement | null>(null);
const isPlay = ref(false);
const data = inject("data");
function playPauseVideo() {
  if (!player.value) {
    return;
  }
  isPlay.value = true;
  player.value.play();
}

onMounted(() => {
  if (player.value) {
    player.value.addEventListener("ended", () => {
      isPlay.value = false;
    });
  }
});
</script>

<template>
  <div class="result-screen">
    <div class="result-screen__container">
      <picture class="result-screen__img">
        <source
          media="(min-width: 780px)"
          :srcset="data['main-hero-desktop']"
        />
        <img :src="data['main-hero-mobile']" alt="Главный герой" />
      </picture>

      <div class="result-screen__body">
        <div class="result-screen__video">
          <video ref="player">
            <source src="@/assets/test-video-2.mp4#t=1" />
          </video>
          <img class="result-screen__mask" :src="data['tv-mask']" />

          <button
            v-if="!isPlay"
            id="btnPlayPause"
            class="play"
            title="play"
            accesskey="P"
            @click="playPauseVideo"
          >
            <PlayIcon />
          </button>
        </div>

        <div class="result-screen__content">
          <p class="result-screen__title">
            Вы Адидас-младший из сериала «Слово пацана»
          </p>

          <p class="result-screen__subtitle">
            Марат предстает перед зрителями как обычный уличный хулиган и
            задира, которому в то же время не чуждо сострадание.
          </p>

          <div class="result-screen__buttons">
            <button class="result-screen__button-man">
              <RepeatIcon class="result-screen__button-man-left" />
              <span class="result-screen__button-man-right">повторить</span>
            </button>

            <button class="result-screen__button result-screen__download">
              <DownloadIcon />
            </button>

            <button class="result-screen__button result-screen__download">
              <ShareIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.result-screen__button-man {
  display: flex;
  box-sizing: border-box;
  background: transparent;
  outline: none;
  width: fit-content;
  border: none;
  cursor: pointer;
}

.result-screen__button-man-left {
  background: rgba(57, 0, 101, 0.8);

  border-style: solid;

  border-color: rgba(217, 63, 255, 1);
  gap: 28px;
  border-radius: 0px 0px 0px 30px;
  border-width: 3px 0px 3px 3px;
  display: none;
  height: 49px;
  flex-shrink: 0;
  @media (min-width: 780px) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
  }
}

.result-screen__button-man-right {
  background: rgba(57, 0, 101, 0.8);
  border-style: solid;
  padding: 16px 37px 16px 37px;
  height: 54px;
  box-sizing: border-box;
  gap: 28px;
  border-radius: 0px 30px 0px 30px;
  border-width: 3px;
  border-color: rgba(217, 63, 255, 1);
  color: #fff;
  text-transform: uppercase;
  font-family: Rostelecom Basis;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  @media (min-width: 780px) {
    border-radius: 0px 30px 0px 0px;
  }
}

.result-screen__button-man:hover .result-screen__button-man-left,
.result-screen__button-man:hover .result-screen__button-man-right {
  box-shadow: inset 1px 1px 8px 0px rgba(217, 63, 255, 1);
  border-style: solid;
}

.result-screen__button-man:active .result-screen__button-man-left,
.result-screen__button-man:active .result-screen__button-man-right {
  border-style: solid;
  background: rgba(119, 0, 212, 0.8);
}

.play {
  all: unset;
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
}
.result-screen__buttons {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  position: relative;
  z-index: 11;
}

.result-screen__button {
  all: unset;
  cursor: pointer;
  border: 2.3px solid rgba(217, 63, 255, 1);
  background: rgba(57, 0, 101, 0.8);
  box-sizing: border-box;
}

.result-screen__download {
  border-radius: 500px;
  padding: 13px;
  display: flex;
  width: 54px;
  height: 54px;
  justify-content: center;
  align-items: center;
}

.result-screen__again {
  border-radius: 0px 23.86px 0px 23.86px;
  padding: 18px 40px 18px 40px;
  color: #fff;
  font-family: Rostelecom Basis;
  font-size: 14.31px;
  font-weight: 500;
  line-height: 14px;
  text-align: center;
}

.result-screen__img {
  margin: 0 auto 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 780px) {
    max-width: 570px;
    margin-bottom: 30px;
  }
}

.result-screen__img img {
  @media (min-width: 780px) {
    width: 100%;
  }
}

.result-screen {
  height: 100%;
  padding: 64px 20px 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (min-width: 780px) {
    padding: 76px 0 0;
  }
}

.result-screen__container {
  @media (min-width: 950px) {
    max-width: 900px;
    margin: 0 auto;
  }
}
.result-screen__video {
  position: relative;
  margin: 0 auto 45px;
  @media (min-width: 780px) {
    margin: 0 60px 0 0;
  }
}

.result-screen__video video {
  width: 100%;
  position: relative;
  top: 12px;
  @media (min-width: 780px) {
  }
}

.result-screen__mask {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 103%;
  height: 115%;
  @media (min-width: 780px) {
    height: 105%;
  }
}

.result-screen__title {
  font-family: Consolation Mono C;
  font-size: 22px;
  font-weight: 400;
  line-height: 28.6px;
  text-align: left;
  color: #ff8833;
  margin-bottom: 20px;

  @media (min-width: 780px) {
    font-family: Consolation Mono C;
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.result-screen__subtitle {
  font-family: Consolation Mono C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #fff;
  margin-bottom: 20px;

  @media (min-width: 780px) {
    font-family: Consolation Mono C;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    margin-bottom: 30px;
  }
}

.result-screen__body {
  @media (min-width: 780px) {
    display: flex;
  }
}
</style>
