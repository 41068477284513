<script setup lang="ts"></script>

<template>
  <svg
    width="347"
    height="347"
    viewBox="0 0 347 347"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="173.5" cy="173.5" r="147.5" stroke="#D93FFF" stroke-width="4" />
    <g filter="url(#filter0_f_4060_782)">
      <circle cx="173.5" cy="173.5" r="147" stroke="#E786FF" stroke-width="5" />
    </g>
    <g filter="url(#filter1_f_4060_782)">
      <circle cx="173.5" cy="173.5" r="148" stroke="#E786FF" stroke-width="3" />
    </g>
    <defs>
      <filter
        id="filter0_f_4060_782"
        x="10"
        y="10"
        width="327"
        height="327"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="7"
          result="effect1_foregroundBlur_4060_782"
        />
      </filter>
      <filter
        id="filter1_f_4060_782"
        x="0"
        y="0"
        width="347"
        height="347"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="12"
          result="effect1_foregroundBlur_4060_782"
        />
      </filter>
    </defs>
  </svg>
</template>

<style scoped></style>
