<script setup lang="ts"></script>

<template>
  <svg
    width="887"
    height="163"
    viewBox="0 0 887 163"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_4026_319)">
      <path d="M443.5 64L823 81.5L443.5 99L64 81.5L443.5 64Z" fill="#FF8459" />
    </g>
    <defs>
      <filter
        id="filter0_f_4026_319"
        x="0"
        y="0"
        width="887"
        height="163"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="32"
          result="effect1_foregroundBlur_4026_319"
        />
      </filter>
    </defs>
  </svg>
</template>

<style scoped></style>
