<script setup lang="ts"></script>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4070_1464)">
      <path
        d="M9.65738 15.919C9.6778 15.9394 9.6992 15.9587 9.72154 15.9771C9.73137 15.9851 9.74173 15.9921 9.75179 15.9996C9.76447 16.0092 9.77692 16.019 9.79012 16.0278C9.80246 16.0361 9.81525 16.0432 9.82788 16.0508C9.83963 16.0578 9.85114 16.0652 9.86324 16.0717C9.87622 16.0786 9.8896 16.0845 9.90286 16.0907C9.91536 16.0967 9.9277 16.103 9.94055 16.1082C9.95352 16.1136 9.96673 16.118 9.97988 16.1226C9.9936 16.1276 10.0071 16.1328 10.0211 16.137C10.0343 16.141 10.0476 16.144 10.0609 16.1473C10.0752 16.1509 10.0894 16.1549 10.1039 16.1578C10.1192 16.1608 10.1347 16.1626 10.1501 16.1648C10.1628 16.1666 10.1754 16.1691 10.1884 16.1704C10.217 16.1732 10.2457 16.1747 10.2744 16.1747C10.3032 16.1747 10.3319 16.1732 10.3605 16.1704C10.3736 16.1691 10.3863 16.1666 10.3993 16.1648C10.4145 16.1626 10.4298 16.1608 10.4449 16.1578C10.4597 16.1549 10.4742 16.1508 10.4887 16.1471C10.5017 16.1438 10.5148 16.141 10.5277 16.1371C10.542 16.1327 10.5558 16.1274 10.5698 16.1224C10.5826 16.1177 10.5956 16.1135 10.6083 16.1083C10.6214 16.1029 10.6339 16.0965 10.6466 16.0905C10.6597 16.0843 10.6729 16.0785 10.6857 16.0717C10.6979 16.0651 10.7094 16.0578 10.7213 16.0507C10.7339 16.0431 10.7467 16.036 10.7589 16.0277C10.7719 16.0191 10.784 16.0095 10.7964 16.0002C10.8068 15.9924 10.8175 15.9852 10.8276 15.977C10.8488 15.9595 10.8691 15.9411 10.8886 15.9218C10.8895 15.9208 10.8906 15.9201 10.8915 15.9192L14.9635 11.8474C15.3042 11.5066 15.3042 10.9542 14.9635 10.6134C14.6228 10.2727 14.0703 10.2727 13.7295 10.6134L11.147 13.1957V2.50488C11.147 2.02299 10.7563 1.63232 10.2744 1.63232C9.79257 1.63232 9.4019 2.02299 9.4019 2.50488V13.1957L6.81961 10.6134C6.47891 10.2727 5.92641 10.2727 5.58565 10.6134C5.24489 10.9542 5.24489 11.5066 5.58565 11.8474L9.65738 15.919Z"
        fill="#D93FFF"
      />
      <path
        d="M18.9994 15.502C18.5175 15.502 18.1268 15.8926 18.1268 16.3745V17.92H2.42088V16.3745C2.42088 15.8926 2.03021 15.502 1.54833 15.502C1.06645 15.502 0.675781 15.8926 0.675781 16.3745V18.7925C0.675781 19.2744 1.06645 19.6651 1.54833 19.6651H18.9994C19.4812 19.6651 19.8719 19.2744 19.8719 18.7925V16.3745C19.8719 15.8926 19.4812 15.502 18.9994 15.502Z"
        fill="#D93FFF"
      />
    </g>
    <defs>
      <clipPath id="clip0_4070_1464">
        <rect
          width="19.9333"
          height="19.9333"
          fill="white"
          transform="translate(0.0332031 0.0333252)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped></style>
