<script setup lang="ts"></script>

<template>
  <svg
    width="121"
    height="121"
    viewBox="0 0 121 121"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_f_4031_753)">
      <circle
        cx="60.7154"
        cy="60.1653"
        r="40.4553"
        stroke="#E990FF"
        stroke-width="2.61002"
      />
      <path
        d="M76.375 60.1653L52.8848 73.7274L52.8848 46.6032L76.375 60.1653Z"
        fill="#E990FF"
      />
    </g>
    <circle
      cx="60.7154"
      cy="60.1653"
      r="40.3906"
      fill="#390065"
      fill-opacity="0.8"
      stroke="#E990FF"
      stroke-width="2.73941"
    />
    <path
      d="M76.375 60.1653L52.8848 73.7274L52.8848 46.6032L76.375 60.1653Z"
      fill="#E990FF"
    />
    <g filter="url(#filter1_f_4031_753)">
      <circle
        cx="60.7154"
        cy="60.1653"
        r="40.4553"
        stroke="#E990FF"
        stroke-width="2.61002"
      />
      <path
        d="M76.375 60.1653L52.8848 73.7274L52.8848 46.6032L76.375 60.1653Z"
        fill="#E990FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_4031_753"
        x="0.684937"
        y="0.134827"
        width="120.062"
        height="120.061"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="9.13507"
          result="effect1_foregroundBlur_4031_753"
        />
      </filter>
      <filter
        id="filter1_f_4031_753"
        x="6.77498"
        y="6.22487"
        width="107.882"
        height="107.881"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="6.09005"
          result="effect1_foregroundBlur_4031_753"
        />
      </filter>
    </defs>
  </svg>
</template>

<style scoped></style>
