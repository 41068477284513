<script setup lang="ts"></script>

<template>
  <svg
    width="23"
    height="24"
    viewBox="0 0 23 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.9958 8.91701C12.796 8.67476 12.6279 8.4054 12.4977 8.11548L9.86133 9.74846C10.0611 9.9907 10.2292 10.2602 10.3594 10.55L12.9958 8.91701Z"
      fill="#D93FFF"
    />
    <path
      d="M10.3594 13.4336C10.2292 13.7234 10.0611 13.9929 9.86133 14.2351L12.4977 15.8681C12.6279 15.5782 12.796 15.3088 12.9958 15.0666L10.3594 13.4336Z"
      fill="#D93FFF"
    />
    <path
      d="M9.72319 11.9917C9.72319 10.5645 8.56215 9.40344 7.13513 9.40344C5.70791 9.40344 4.54688 10.5645 4.54688 11.9917C4.54688 13.4187 5.70791 14.58 7.13513 14.58C8.56215 14.58 9.72319 13.4189 9.72319 11.9917Z"
      fill="#D93FFF"
    />
    <path
      d="M15.7209 9.26177C17.1481 9.26177 18.3091 8.10073 18.3091 6.6737C18.3091 5.24649 17.1481 4.08545 15.7209 4.08545C14.2938 4.08545 13.1328 5.24649 13.1328 6.6737C13.1328 8.10073 14.2938 9.26177 15.7209 9.26177Z"
      fill="#D93FFF"
    />
    <path
      d="M15.7209 14.7217C14.2938 14.7217 13.1328 15.8827 13.1328 17.3097C13.1328 18.737 14.2938 19.898 15.7209 19.898C17.1481 19.898 18.3091 18.737 18.3091 17.3097C18.3091 15.8827 17.1481 14.7217 15.7209 14.7217Z"
      fill="#D93FFF"
    />
  </svg>
</template>

<style scoped></style>
