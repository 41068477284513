<script setup lang="ts"></script>

<template>
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M26.7069 14.2059H26.7057C26.5359 14.206 26.3678 14.2396 26.2109 14.3047C26.0541 14.3698 25.9116 14.4652 25.7916 14.5853C25.6716 14.7055 25.5765 14.8481 25.5117 15.0051C25.4468 15.162 25.4135 15.3302 25.4137 15.5C25.412 17.3127 24.9135 19.0903 23.9722 20.6395C23.031 22.1888 21.683 23.4504 20.0748 24.2873C18.4666 25.1242 16.6598 25.5043 14.8507 25.3864C13.0416 25.2684 11.2994 24.6568 9.8136 23.6182C5.33608 20.4833 4.24396 14.2906 7.3792 9.81359C10.5144 5.33658 16.708 4.2445 21.1855 7.37944C21.8629 7.85246 22.4786 8.40826 23.0181 9.03392H19.379C19.0361 9.03392 18.7071 9.17015 18.4646 9.41263C18.2221 9.65511 18.0859 9.98399 18.0859 10.3269C18.0859 10.6698 18.2221 10.9987 18.4646 11.2412C18.7071 11.4837 19.0361 11.6199 19.379 11.6199H25.4137C25.7567 11.6199 26.0856 11.4837 26.3281 11.2412C26.5706 10.9987 26.7069 10.6698 26.7069 10.3269V4.29298C26.7069 3.95006 26.5706 3.62119 26.3281 3.37871C26.0856 3.13622 25.7567 3 25.4137 3C25.0707 3 24.7418 3.13622 24.4993 3.37871C24.2568 3.62119 24.1206 3.95006 24.1206 4.29298V6.44618C23.6668 6.01567 23.1816 5.6195 22.669 5.26094C21.3244 4.31956 19.8075 3.65221 18.2049 3.29698C16.6024 2.94176 14.9455 2.90562 13.329 3.19063C11.7125 3.47564 10.1679 4.07621 8.78355 4.95806C7.39916 5.83992 6.20204 6.98578 5.26054 8.33022C3.35909 11.0454 2.61429 14.4047 3.18996 17.669C3.76563 20.9333 5.61463 23.8353 8.3302 25.7365C10.421 27.2089 12.9157 27.9995 15.4731 28C16.2096 27.9996 16.9448 27.935 17.6701 27.8069C20.5643 27.2964 23.1861 25.7827 25.0752 23.5317C26.9642 21.2807 27.9998 18.4362 28 15.4977C27.9997 15.155 27.8633 14.8264 27.6209 14.5842C27.3784 14.3419 27.0497 14.2059 26.7069 14.2059Z"
      fill="white"
    />
  </svg>
</template>

<style scoped></style>
