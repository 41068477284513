<template>
  <div class="root">
    <div class="root__background"></div>

    <transition name="fade">
      <main-loader v-if="appState === AppState.LOADING" :progress="progress">
      </main-loader>

      <start-screen
        v-else-if="appState === AppState.START"
        @select-gender="appState = AppState.CHOOSE_EPOCH"
      />

      <select-screen
        v-else-if="appState === AppState.CHOOSE_EPOCH"
        @select="appState = AppState.PHOTO"
      />

      <photo-screen
        v-else-if="appState === AppState.PHOTO"
        @show-result="appState = AppState.RESULT"
      />

      <result-screen v-else-if="appState === AppState.RESULT" />
    </transition>

    <div class="root__stripes" />

    <img src="@/assets/logo.svg" alt="logo" class="root__logo" />

    <epoch-progress
      :state="appState"
      v-if="appState !== AppState.LOADING && appState !== AppState.START"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, provide, ref } from "vue";
import DataStorage from "@/utils/data-storage";
import MainLoader from "@/components/MainLoader.vue";
import { forceRerender } from "@/utils/force-rerender";
import StartScreen from "@/components/StartScreen.vue";
import SelectScreen from "@/components/SelectScreen.vue";
import EpochProgress from "@/components/EpochProgress.vue";
import PhotoScreen from "@/components/PhotoScreen.vue";
import ResultScreen from "@/components/ResultScreen.vue";
import { AppState } from "@/types/AppState";

const back = ref(false);
const currentIndex = ref(0);

const next = () => {
  back.value = false;
  currentIndex.value++;
};

const defaultUuid = ref("");

const appState = ref<AppState>(AppState.LOADING);

if (window.location.pathname.includes("result")) {
  const els = window.location.pathname.split("/");
  currentIndex.value = 1;
  defaultUuid.value = els[els.length - 1];
}

const newDataStorage = new DataStorage();
const isLoading = ref(true);
const data = ref({});
const mainAssets = ref({});
const progress = ref(0);

newDataStorage.loadMainAssets().then(() => {
  mainAssets.value = newDataStorage.mainAssets;
  newDataStorage.load((p, isFinished) => {
    progress.value = p;

    if (isFinished) {
      isLoading.value = false;
      data.value = newDataStorage.loadedAssets;
      appState.value = AppState.START;
    }
  });
});

provide("data", data);

const onAfterLeave = () => {
  forceRerender();
};

const desktopImg = computed(() => {
  return `url(${mainAssets.value.desktop})`;
});

const stripes = computed(() => {
  return `url(${mainAssets.value.stripes})`;
});
</script>

<style>
.fade-enter-active {
  transition: all 0.3s ease-out;
}

.fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter-from {
  transform: translate(0, 100vh);
}
.slide-leave-to {
  transform: translate(0, -100vh);
}

.div-slider {
  overflow: hidden;
  position: relative;
  height: 100vh;
}
.root {
  position: absolute;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
}

.div-slider .card {
  position: absolute;
  height: 100vh;
  background-color: #60adff;
  overflow: auto;
}

.root__logo {
  position: absolute;
  left: 18px;
  top: 18px;
  width: 212px;
  height: 20px;
  z-index: 10;

  @media (min-width: 768px) {
    width: 325px;
    height: 32px;
    top: 21px;
    right: 30px;
  }
}

.root__background {
  background-image: v-bind(desktopImg);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;
}
.root__stripes {
  background-image: v-bind(stripes);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  opacity: 0.25;
  z-index: 9;
}
</style>
