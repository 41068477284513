<script setup lang="ts">
import { computed, ref } from "vue";
import { AppState } from "@/types/AppState";

const props = defineProps<{ state: AppState }>();
const items = [AppState.CHOOSE_EPOCH, AppState.PHOTO, AppState.RESULT];

const title = computed(() => {
  switch (props.state) {
    case AppState.CHOOSE_EPOCH:
      return "Выбор эпохи";
    case AppState.PHOTO:
      return "Загрузка фото";
    case AppState.RESULT:
      return "Результат";
    default:
      return "";
  }
});
</script>

<template>
  <div class="epoch-progress">
    <p class="epoch-progress__text">
      {{ title }}
    </p>

    <div class="epoch-progress__container" :class="state.toLowerCase()">
      <div
        v-for="item in items"
        :key="item"
        class="epoch-progress__dot"
        :class="{ 'epoch-progress__dot--active': item === state }"
      ></div>
    </div>
  </div>
</template>

<style scoped>
.epoch-progress {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  z-index: 11;
}

.epoch-progress__text {
  font-family: Consolation Mono C;
  font-size: 11px;
  font-weight: 400;
  line-height: 11px;
  text-align: center;
  color: rgba(255, 136, 51, 1);
  margin-bottom: 8px;
}

.epoch-progress__container {
  display: flex;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  transform: translateX(41%);
}

.epoch-progress__container.photo {
  transform: translateX(32%);
}

.epoch-progress__container.result {
  transform: translateX(26%);
}

.epoch-progress__dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: rgba(255, 136, 51, 1);
  position: relative;
}

.epoch-progress__dot.epoch-progress__dot--active {
  width: 14px;
  height: 14px;
  background: transparent;
  box-sizing: border-box;
  border: 2px solid #ff8833;
}

.epoch-progress__dot.epoch-progress__dot--active::after {
  content: " ";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 4px;
  background: rgba(255, 136, 51, 1);
  height: 4px;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
</style>
