<script setup lang="ts">
import SelectScreenTextBg from "@/components/svg/SelectScreenTextBg.vue";
import { computed, inject, ref } from "vue";

const isLoading = ref(false);

const title = computed(() => {
  return isLoading.value ? "Готовим гримёров..." : "Загрузите ваше фото";
});

const data = inject("data");
const emit = defineEmits<{
  (e: "show-result"): void;
}>();
</script>

<template>
  <div class="photo-screen">
    <div>
      <SelectScreenTextBg class="photo-screen__bg" />
      <p class="photo-screen__text">{{ title }}</p>
    </div>

    <div id="drop-area">
      <form class="my-form" @click.prevent="emit('show-result')">
        <input type="file" id="fileElem" accept="image/*" />

        <label for="fileElem">
          <span class="photo-screen__hint">
            На фото должно быть хорошо видно ваше лицо
          </span>
          <span class="photo-screen__hint">
            Принимаются фогографии до 5 mb в формате .jpg или .png
          </span>
          <span class="photo-screen__hint">
            Кликните по этому полю или перетащите сюда фотографию
          </span>
        </label>
      </form>
    </div>

    <img :src="data['man']" class="photo-screen__man" />
    <img :src="data['woman']" class="photo-screen__woman" />
  </div>
</template>

<style scoped>
.photo-screen {
  height: 100%;
  padding: 128px 0 0;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;

  @media (min-width: 780px) {
    padding-top: 162px;
  }
}

.photo-screen__bg {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 60px;

  @media (min-width: 780px) {
    top: 110px;
  }
}

.photo-screen__man,
.photo-screen__woman {
  display: none;
  position: absolute;
  bottom: 0;
  z-index: 1;

  @media (min-width: 780px) {
    display: block;
  }
}

.photo-screen__woman {
  left: 5%;
  height: 80%;
  max-height: 500px;
}

.photo-screen__man {
  right: 0;
  height: 80%;
  max-height: 600px;
}

.photo-screen__hint {
  font-family: Consolation Mono C;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: center;
  color: #fff;
  margin-bottom: 17px;
  display: block;

  @media (min-width: 780px) {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
}

.photo-screen__hint:last-child {
  margin-bottom: 0;
}

.photo-screen__text {
  position: relative;
  z-index: 11;
  margin-bottom: 54px;
  text-align: center;
  color: rgba(255, 136, 51, 1);
  font-family: Consolation Mono C;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;

  @media (min-width: 780px) {
    font-family: Consolation Mono C;
    font-size: 33px;
    font-weight: 400;
    line-height: 46.2px;
    text-align: center;
  }
}
.photo-loader {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.error {
  position: absolute;
  top: 120%;
  border: 3px solid #6f0c0c;
  box-shadow: 5px 4px 4px 0px #00000040;
  border-radius: 10px;
  background: #f0f0f0;
  font-family: Maki-Sans;
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px;

  @media (min-width: 800px) {
    font-size: 20px;
    border-radius: 20px;
    padding: 20px;
  }
}

#drop-area {
  background-image: url("@/assets/plus.svg");
  background-color: rgba(255, 136, 51, 0.1);

  border: 4px dashed rgba(255, 136, 51, 1);
  border-radius: 20px;
  font-family: sans-serif;
  padding: 36px 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 11;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0 22px 12px;
  box-sizing: border-box;

  @media (min-width: 780px) {
    padding: 50px 60px;
    width: 650px;
    height: 350px;
    margin: 0 auto;
    box-sizing: border-box;
  }
}

#drop-area.highlight {
  border-color: purple;
}

#fileElem {
  display: none;
}

.photo-loader--success #drop-area {
  background: linear-gradient(
    0deg,
    rgba(50, 191, 132, 0.05),
    rgba(50, 191, 132, 0.05)
  );
  border: 2px dashed #32bf84;
}

.photo-loader--success .text {
  color: #32bf84;
}

@media (min-width: 600px) {
  .header-icon {
    width: 12%;
    margin-bottom: 12px;
  }

  .header-title {
    margin-bottom: 12px;
  }
}

@media (min-width: 950px) {
  .header-icon {
    width: 57px;
    height: 57px;
  }
  .text {
    display: block;
    font-family: Rostelecom Basis;
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
  }
}
</style>
